
.data-situation {
    height: 100%;
    display: flex;
    flex-direction: column;

    ::v-deep.tooltip {
        height: 70px;
        border-radius: 2px;
        box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
        background: #fff;

        .tooltip-box {
            padding: 13px 20px 0;
        }

        .tooltip-title {
            font-size: 12px;
            color: #999999;
        }

        .tooltip-title-right {
            float: right;
            padding-right: 10px;
            font-size: 16px;
            color: #5F55F1;
        }

        .tooltip-title-left {
            color: #333;
        }
    }

    .head-box {
        padding: 16px 0 0 52px;

        span {
            font-size: 14px;
            color: #666666;
            font-weight: 400;
        }
    }

    .data-situation-header {
        display: flex;
        justify-content: space-between;
        padding: 34px 132px 82px 52px;

        .situation-top-item {
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                line-height: 1;

                &:nth-child(1) {
                    font-size: 14px;
                    color: #666666;
                    font-weight: 400;
                }

                &:nth-child(2) {
                    color: #584EEE;
                    font-size: 24px;
                    font-weight: bold;
                    margin-top: 20px;
                }
            }
        }
    }

    .data-situation-basics, .data-situation-view, .data-situation-interaction {
        padding: 0 50px 0 20px;

        &.data-situation-interaction {
            padding-bottom: 40px;
        }

        .situation-basics-header {
            display: flex;
            line-height: 1;
            justify-content: space-between;

            .basics-title {
                display: flex;
                align-items: center;

                .title-line {
                    width: 5px;
                    height: 20px;
                    background: #584EEE;
                    border-radius: 3px;
                    margin-right: 10px;
                }

                span {
                    font-size: 16px;
                    color: #333;
                }
            }

            .basics-select-time {
                display: flex;

                .basics-time-item {
                    background: #F1F0FE;
                    font-size: 12px;
                    color: #666666;
                    line-height: 30px;
                    text-align: center;
                    width: 50px;
                    height: 30px;
                    cursor: pointer;

                    &:nth-child(1) {
                        border-radius: 5px 0px 0px 5px;
                    }

                    &:nth-child(2) {
                        border-radius: 0px 5px 5px 0px;
                    }
                }

                .time-item-active {
                    background: #564CED;
                    color: #fff;
                }
            }
        }

        .situation-basics-select {
            margin-top: 22px;
            padding-left: 14px;
        }
    }
}
